import debounce from 'lodash.debounce';
import simpleLightbox from 'simplelightbox';
import BaseFilter from '../modules/filters/baseFilter';
import PopupVideo from '../modules/popupVideo';

$ = jQuery;

const rootSections = document.querySelectorAll('.gallery-section');

export default class GallerySection {
    constructor(el) {
        this.set(el);
        this.init();
    }
    set(el) {
        if (!el) return false;
        this.root = el;
        this.tabsContents = this.root.querySelectorAll(
            '.gallery-section__tab-content'
        );
        this.filterSwitcherWrapper =
            this.root.querySelector('.filter-switcher');
        this.showMoreButtons = this.root.querySelectorAll('[data-show-more]');
        this.galleryInstance = null;
    }

    init() {
        this.showMoreHandler();
        this.tabsHandler();
        this.filterSwitcher();
        this.setMediaCards();
        this.refreshHandler();
        this.setLightbox();
    }

    setLightbox() {
        const galleries = this.root.querySelectorAll(
            '.gallery-section__image a'
        );

        if (this.galleryInstance !== null) this.galleryInstance.destroy();
        if (galleries && galleries.length > 0)
            this.galleryInstance = new simpleLightbox(galleries, {
                closeText: '',
            });
    }

    showMoreHandler() {
        if (!this.showMoreButtons) return;
        this.showMoreButtons.forEach(button => {
            button.addEventListener(
                'click',
                debounce(e => {
                    this.showMore(button);
                }, 0)
            );
        });
    }

    tabsHandler() {
        if (!this.filterSwitcherWrapper) return;
        this.root.addEventListener('filter-switcher-change', e =>
            this.openTab(e)
        );
    }

    refreshHandler() {
        if (!this.refreshButton) return;

        this.refreshButton.addEventListener(
            'click',
            this.refreshComponentFunction.bind(this)
        );
    }

    filterSwitcher() {
        new BaseFilter(this.filterSwitcherWrapper, this.root);
    }

    setMediaCards() {
        const cards = this.root.querySelectorAll('[data-card-media]');
        if (!cards) return;

        cards.forEach(card => {
            const dataContent = card.getAttribute('data-card-media');
            if (dataContent) {
                card.addEventListener('click', event => {
                    card.classList.add("-block");
                    this.onMediaCard(event);
                });
            }
        });
    }

    async showMore(button) {
        const galleryArray = JSON.parse(
            button.getAttribute('data-gallery-gallery')
        );
        const ajaxResponseHtmlWrapper =
            button.parentElement.parentElement.querySelector(
                `.${button.dataset.ajaxWrapper}`
            );
        const actualPage = button.dataset.galleryPage;
        const galleryType = button.dataset.galleryType;
        const perPage = this.root.dataset.perPage;
        const perRow = this.root.dataset.perRow;
        const fileType = this.root.dataset.type;
        const data = new FormData();
        data.append('action', 'load_gallery_items');
        data.append('page', actualPage);
        data.append('gallery_type', galleryType);
        data.append('gallery_array', JSON.stringify(galleryArray));
        data.append('per_page', parseInt(perPage));
        data.append('per_row', parseInt(perRow));
        data.append('type', fileType);
        try {
            const response = await fetch(ajax.url, {
                body: data,
                method: 'POST',
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const responseData = await response.json();
            const { page, max_pages, html } = responseData;

            if (page) {
                button.dataset.galleryPage = page;
            }

            if (html) {
                ajaxResponseHtmlWrapper.innerHTML += html;
                this.setMediaCards();
            }

            if (parseInt(page) === parseInt(max_pages)) {
                button.classList.add('d-none');
            }

            this.setLightbox();
        } catch (error) {
            console.error(error);
        }
    }

    openTab(e) {
        const { detail } = e;
        const parseddDetail = parseInt(detail.value);

        if (
            !this.tabsContents[parseddDetail].classList.contains(
                'gallery-section__tab--active'
            )
        ) {
            document
                .querySelector('.gallery-section__tab-content--active')
                .classList.remove('gallery-section__tab-content--active');
            this.tabsContents[parseddDetail].classList.add(
                'gallery-section__tab-content--active'
            );
        }
    }

    onMediaCard(event) {
        const { currentTarget } = event;
        const type = currentTarget.getAttribute('data-type');
        const id = currentTarget.getAttribute('data-card-media');
        const subtitle = currentTarget.getAttribute('data-subtitle');
        const loader = currentTarget.querySelector(".card-media-movie__loader");

        const formData = new FormData();
        formData.append('action', 'media_popup');
        formData.append('type', type);
        formData.append('id', id);
        formData.append('subtitle', subtitle);

        loader.classList.remove("hide");

        $.ajax({
            type: 'POST',
            url: ajax.url,
            enctype: 'multipart/form-data',
            processData: false,
            contentType: false,
            data: formData,
            success: function (response) {
                const data = JSON.parse(response);
                const { html } = data;

                $('body').append(html);
                this.setPopup();
            }.bind(this),
            error: function () {}.bind(this),
            complete: function () {
                currentTarget.classList.remove("-block");
                loader.classList.add("hide");
            }.bind(this),
        });
    }
    setPopup() {
        const popup = document.querySelector('[data-popup]');
        if (popup) new PopupVideo(popup);
    }

    refreshComponentFunction() {
        const formData = new FormData();
        formData.append('action', 'refresh_gallery');
        formData.append('blockId', this.blockId);
        $.ajax({
            type: 'POST',
            url: ajax.url,
            enctype: 'multipart/form-data',
            processData: false,
            contentType: false,
            data: formData,
            success: function (response) {
                const data = JSON.parse(response);
                const { html } = data;
                this.root.innerHTML = html;
            }.bind(this),
        });
    }
}

const initGalleries = rootSections => {
    if (!rootSections) return;

    rootSections.forEach(el => {
        new GallerySection(el);
    });
};

initGalleries(rootSections);
